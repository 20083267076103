import { createContext, useEffect, useMemo, useState } from 'react';
import { deepmerge } from 'deepmerge-ts';
import { getGivingFormById } from 'services/givingFormService';
import { getOrganizationData } from 'services/organizationService';
import { useQueryParam } from 'hooks';
import {
  BlockTypes,
  Designation,
  DonorFees,
  IGivingFormConfig,
  IPaymentGateway,
  IPaymentInfoSection,
  OrganizationFeatures,
  TransactionStats
} from 'types';
import { IDonateQueryParam } from 'types/QueryParams';
import { isLocalStorageAvailable } from 'utils';
import { PAYMENT_OPTIONS_MAP } from './GivingFormUtils';

type GivingFormUpdate = Partial<IGivingFormConfig>;

/**
 * Type representing the object returned from useGivingFormData hook
 */
type GivingFormContextType = {
  config?: IGivingFormConfig;
  givingFormVersion?: number;
  givingFormVariant?: string;
  setConfig: (configChange: GivingFormUpdate) => void;
  overwriteConfig: (configChange: IGivingFormConfig) => void;
  referrer?: string;
  setReferrer: (value?: string) => void;
  loadingError: boolean;
  isLoaded: boolean;
  setGivingFormId: (givingFormId: string) => void;
  givingFormId: string;
  campaignId?: string;
  organizationId?: string;
  organizationName?: string;
  donorFees?: DonorFees;
  paymentGateway?: IPaymentGateway;
  paypalGatewayId?: string;
  digitalWalletGatewayId?: string;
  digitalWalletMerchantId?: string;
  doubleTheDonationKey?: string;
  doubleTheDonationPartnerKey?: string;
  referenceCode?: string;
  givingFormName: string;
  organizationFeatures?: OrganizationFeatures;
  acceptedPaymentOptions: string[];
  isPlaidEnabled: boolean;
  plaidThreshold: number;
  showPlaidOnSubmit: boolean;
  setShowPlaidOnSubmit: (value: boolean) => void;
  heapIsEnabled: boolean;
  campaignDesignations: Designation[];
  transactionStats: TransactionStats | null;
};

/**
 * Giving Form context object
 */
export const GivingFormContext = createContext<GivingFormContextType | null>(
  null
);

/**
 * Prop Types for Giving Form Provider
 */
type GivingFormProviderProps = {
  children: React.ReactNode;
};
/**
 * GivingFormProvider that configures the giving form context value. Wrap your form in this.
 */
export const GivingFormProvider = ({ children }: GivingFormProviderProps) => {
  const [givingFormConfig, setGivingFormConfig] = useState<IGivingFormConfig>();
  const [givingFormVersion, setGivingFormVersion] = useState<number>();
  const [givingFormVariant, setGivingFormVariant] = useState<string>();
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [givingFormId, setGivingFormId] = useState<string>('');
  const [campaignId, setCampaignId] = useState<string>();
  const [referenceCode, setReferenceCode] = useState<string>();
  const [organizationId, setOrganizationId] = useState<string>();
  const [organizationName, setOrganizationName] = useState<string>();
  const [organizationFeatures, setOrganizationFeatures] =
    useState<OrganizationFeatures>();
  const [acceptedPaymentOptions, setAcceptedPaymentOptions] = useState<
    string[]
  >([]);
  const [donorFees, setDonorFees] = useState<DonorFees>();
  const [paymentGateway, setPaymentGateway] = useState<IPaymentGateway>();
  const [paypalGatewayId, setPaypalGatewayId] = useState<string>();
  const [givingFormName, setGivingFormName] = useState<string>('');
  const [digitalWalletGatewayId, setDigitalWalletGatewayId] =
    useState<string>();
  const [digitalWalletMerchantId, setDigitalWalletMerchantId] =
    useState<string>();
  const [doubleTheDonationKey, setDoubleTheDonationKey] = useState<string>();
  const [doubleTheDonationPartnerKey, setDoubleTheDonationPartnerKey] =
    useState<string>();
  const [isPlaidEnabled, setIsPlaidEnabled] = useState(false);
  const [plaidThreshold, setPlaidThreshold] = useState<number>(0);
  const [showPlaidOnSubmit, setShowPlaidOnSubmit] = useState(false);
  const [heapIsEnabled, setHeapIsEnabled] = useState(false);
  const [campaignDesignations, setCampaignDesignations] = useState<
    Designation[]
  >([]);
  const [transactionStats, setTransactionStats] =
    useState<TransactionStats | null>(null);
  const referenceCodeQueryParam = useQueryParam(
    IDonateQueryParam.ReferenceCode
  );

  const [referrer, setReferrer] = useState<string>();

  useEffect(() => {
    // If the givingFormId is edit, then we are expecting config data from eventHub instead of an API call
    if (!givingFormId || givingFormId === 'undefined') {
      return;
    }

    // If the form is loaded in the editor we need to fetch transaction fee rates.  All other data
    // will come from eventHub
    if (givingFormId.includes('edit')) {
      const getDonorFees = async () => {
        const givingFormIdFromUrl = givingFormId
          .replace('edit-', '')
          .replace('screenshot-service-', '');
        const givingFormDataPromise = getGivingFormById(givingFormIdFromUrl);
        const organizationDataPromise =
          getOrganizationData(givingFormIdFromUrl);

        const [givingFormData, organizationData] = await Promise.all([
          givingFormDataPromise,
          organizationDataPromise
        ]);

        setDonorFees(organizationData.donorFees as DonorFees);
        setDoubleTheDonationKey(
          organizationData.doubleTheDonationPublicKey as string
        );
        setDoubleTheDonationPartnerKey(
          organizationData.doubleTheDonationPartnerKey as string
        );
        setOrganizationId(givingFormData.organizationId as string);
        setAcceptedPaymentOptions(
          organizationData.acceptedPaymentOptions ?? []
        );
        setOrganizationFeatures(organizationData.features);
        setCampaignDesignations(organizationData.designations);
        // do not set heapIsEnabled to true in edit mode
      };

      getDonorFees();
      return;
    }

    const getGivingForm = async () => {
      try {
        // If we have access to local storage, see if we should be fetching a variant
        // If not (incognito + iframe) then leave undefined.
        let variantFromStorage;
        if (isLocalStorageAvailable()) {
          variantFromStorage =
            localStorage.getItem(`ab-test-variant-${givingFormId}`) ||
            undefined;
        }

        const givingFormDataPromise = getGivingFormById(
          givingFormId,
          variantFromStorage
        );
        const organizationDataPromise = getOrganizationData(givingFormId);

        const [givingFormData, organizationData] = await Promise.all([
          givingFormDataPromise,
          organizationDataPromise
        ]);

        if (givingFormData?.variant) {
          if (isLocalStorageAvailable()) {
            localStorage.setItem(
              `ab-test-variant-${givingFormId}`,
              givingFormData.variant
            );
          }

          setGivingFormVariant(givingFormData.variant);
        }

        const paymentBlock = (
          givingFormData.config as IGivingFormConfig
        ).blocks.find((block) => block.blockType === BlockTypes.PaymentSection);
        const gateway: IPaymentGateway = (paymentBlock as IPaymentInfoSection)
          .paymentGateway || {
          id: organizationData.defaultGateway.id,
          backendName: organizationData.defaultGateway.backend_name,
          gatewayType: organizationData.defaultGateway.gateway_type,
          isDefault: organizationData.defaultGateway.is_default,
          name: organizationData.defaultGateway.name
        };

        setGivingFormConfig(givingFormData.config);
        setGivingFormVersion(givingFormData.version);
        setCampaignId(givingFormData.campaignId as string);
        setOrganizationId(givingFormData.organizationId as string);
        setOrganizationName(organizationData.organizationName as string);
        setReferenceCode(
          referenceCodeQueryParam || givingFormData.referenceCode
        );
        setGivingFormName(givingFormData.name);
        setDonorFees(organizationData.donorFees as DonorFees);
        setPaymentGateway(gateway);
        setPaypalGatewayId(organizationData.paypalGatewayId as string);
        setDigitalWalletGatewayId(
          organizationData.digitalWalletGatewayId as string
        );
        setDigitalWalletMerchantId(
          organizationData.digitalWalletMerchantId as string
        );
        setDoubleTheDonationKey(
          organizationData.doubleTheDonationPublicKey as string
        );
        setDoubleTheDonationPartnerKey(
          organizationData.doubleTheDonationPartnerKey as string
        );
        setOrganizationFeatures(organizationData.features);
        setAcceptedPaymentOptions(
          organizationData.acceptedPaymentOptions ?? []
        );
        setIsPlaidEnabled(organizationData.plaidEnabled);
        setPlaidThreshold(organizationData.plaidThreshold ?? 0);
        /*
         * managing heap in its own piece of state allows us to keep
         * heapIsEnabled as false in edit mode, even if the flag is returned
         * as true for the org
         */
        setHeapIsEnabled(organizationData.features.heapIsEnabled);
        setCampaignDesignations(organizationData.designations);
        setTransactionStats(givingFormData.transactionStats ?? null);
        setIsLoaded(true);
      } catch (error) {
        setLoadingError(true);
        throw error;
      }
    };

    getGivingForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [givingFormId, referenceCodeQueryParam]);

  const givingFormContextApi = useMemo(
    () => ({
      config: givingFormConfig,
      /**
       * setConfig is here for potential use in the future. There are currently concerns about using setConfig to update
       * the giving form configuration due to assumed architecture of the event system between GMS and Giving Form
       * This remains here in case we find it useful in the future, but until then don't use it!
       */
      setConfig: (configChange: GivingFormUpdate): void => {
        const newConfig = deepmerge(
          { ...givingFormConfig },
          { ...configChange }
        );
        setGivingFormConfig(newConfig as IGivingFormConfig);
      },
      overwriteConfig: (newConfig: IGivingFormConfig): void => {
        setGivingFormConfig(newConfig);
        setIsLoaded(true);
      },
      referrer,
      setReferrer,
      givingFormVersion,
      givingFormVariant,
      loadingError,
      setGivingFormId,
      givingFormId,
      isLoaded,
      campaignId,
      organizationId,
      organizationName,
      referenceCode,
      donorFees,
      paymentGateway,
      paypalGatewayId,
      digitalWalletGatewayId,
      digitalWalletMerchantId,
      doubleTheDonationKey,
      doubleTheDonationPartnerKey,
      givingFormName,
      organizationFeatures,
      acceptedPaymentOptions: acceptedPaymentOptions.map(
        (option) => PAYMENT_OPTIONS_MAP[option]
      ),
      isPlaidEnabled,
      plaidThreshold,
      showPlaidOnSubmit,
      setShowPlaidOnSubmit,
      heapIsEnabled,
      campaignDesignations,
      transactionStats
    }),
    [
      referrer,
      givingFormConfig,
      givingFormVersion,
      givingFormVariant,
      setGivingFormConfig,
      loadingError,
      setGivingFormId,
      givingFormId,
      isLoaded,
      campaignId,
      organizationId,
      organizationName,
      referenceCode,
      donorFees,
      paymentGateway,
      paypalGatewayId,
      digitalWalletGatewayId,
      digitalWalletMerchantId,
      doubleTheDonationKey,
      doubleTheDonationPartnerKey,
      givingFormName,
      organizationFeatures,
      acceptedPaymentOptions,
      isPlaidEnabled,
      plaidThreshold,
      showPlaidOnSubmit,
      setShowPlaidOnSubmit,
      heapIsEnabled,
      campaignDesignations,
      transactionStats
    ]
  );

  return (
    <GivingFormContext.Provider value={givingFormContextApi}>
      {children}
    </GivingFormContext.Provider>
  );
};
