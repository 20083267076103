import axios from 'axios';
import { resolvedEnvironment } from './environment';

const createAxiosInstance = () => {
  const instance = axios.create();

  // dynamic baseUrl from envConfig:
  instance.interceptors.request.use(async (config) => ({
    ...config,
    url: `${resolvedEnvironment.baseUrl}${resolvedEnvironment.apiPrefix}${config.url}`,
    // enabling creds
    withCredentials: true
  }));

  return instance;
};

export default createAxiosInstance();
